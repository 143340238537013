// Import jQuery
import $ from '../../gulp/node_modules/jquery'
import select2 from "../../gulp/node_modules/select2";
// export for others scripts to use
window.$ = window.jQuery = $
select2($);

// Import modules
import LazyLoad from './modules/vanilla-lazyload-master/dist/lazyload.min.js';
import homepageFeed from './modules/homepage-feed.js'
import summary from './modules/summary.js'
import tabs from './modules/tabs.js'
import browsetClassHelpers from './modules/browser-class-helpers.js'
import header from './modules/header.js'
import heroAnimate from './modules/hero-animate.js'
import materialForm from './modules/material-form.js'
import navScrollHighlight from './modules/nav-scroll-highlight.js'
import scrollAnchor from './modules/scroll-anchor.js'
import ssTooltip from './modules/tooltip'
import multiSelect from './modules/multi-select.js'
import headerSearch from './modules/header-search.js'
import studyCardHover from './modules/study-card-hover.js'
import eventCardHover from './modules/event-card-hover.js'
import feedItemHover from './modules/feed-item-hover.js'
import inTheMediaCardHover from './modules/in-the-media-card-hover.js'
import studyHighlightsHover from './modules/study-highlights-hover.js'
import thematicHighlightsSlider from './modules/thematic-highlights-slider.js'
import studyAlsoReadSlider from './modules/study-also-read-slider.js'
import lastStudiesSlider from './modules/last-studies-slider.js'
import photoLibrarySlider from './modules/photo-library-slider.js'
import popin from './modules/popin.js'
import newsletterPopin from './modules/newsletter-popin.js'
import studyHero from './modules/study-hero.js'
import don from './modules/don.js'
import filters from './modules/filters.js'
import showMoreAjax from './modules/show-more-ajax.js'
import sharer from './modules/sharer.js'
import sidehero from './modules/sidehero.js'
import note from './modules/note.js'
import team from './modules/team.js'
import scrollNav from './modules/scrollNav.js'
import textCloud from './modules/text-cloud.js'

$(function () {
    $('html').removeClass('no-js')
    
    window.lazyLoadInstance = new LazyLoad({});

    browsetClassHelpers.init()

    header.init()


    // !!!!!!!!!! DEV GRID - TEMPORARY !!!!!!!!!!
    //dev*
    var devgridCode = [68, 69, 86, 220],
        devgridN = 0
    $(document).keydown(function (e) {
        if (e.keyCode === devgridCode[devgridN++]) {
            if (devgridN === devgridCode.length) {
                $('.dev-grid__btn').toggle()
                devgridN = 0
                return false
            }
        } else {
            devgridN = 0
        }
    })
    $('#js-dev-grid-button').on('click', () => {
        $('.dev-grid').not('#js-dev-grid').hide()
        $('#js-dev-grid').toggle()
    })
    $('#js-dev-grid-button-25').on('click', () => {
        $('.dev-grid').not('#js-dev-grid-25').hide()
        $('#js-dev-grid-25').toggle()
    })
    $('#js-dev-grid-button-60').on('click', () => {
        $('.dev-grid').not('#js-dev-grid-60').hide()
        $('#js-dev-grid-60').toggle()
    })
    // !!!!!!!!!! END DEV GRID !!!!!!!!!!

    // HOMEPAGE FEED
    if ($('.js-homepageFeed').length > 0) {
        homepageFeed.init()
    }
    // MULTI-SELECT
    if ($('.js-multi-select').length > 0) {
        multiSelect.init()
    }
    // MULTI-SELECT
    if ($('.js-summary').length > 0) {
        summary.init()
    }
    // TABS
    if ($('.js-tabs').length > 0) {
        tabs.init()
    }
    // HEADER SEARCH
    if ($('.js-headerSearchForm').length > 0) {
        headerSearch.init()
    }

    if ($('.js-header-animate').length) {
        heroAnimate.init()
    }
    if ($('.materialForm').length) {
        materialForm.init()
    }
    if ($('.js-nav-link').length) {
        navScrollHighlight.init()
    }
    if ($('.js-popin').length) {
        popin.init()
    }
    if ($('.js-newsletter-popin-open').length) {
        newsletterPopin.init()
    }
    if ($('.js-scroll-anchor').length) {
        scrollAnchor.init()
    }
    if ($('.js-tooltip').length) {
        ssTooltip.init()
    }
    if ($('.js-study-card').length) {
        studyCardHover.init()
    }
    if ($('.js-event-card').length) {
        eventCardHover.init()
    }
    if ($('.js-feed-item').length) {
        feedItemHover.init()
    }
    if ($('.js-in-the-media-card').length) {
        inTheMediaCardHover.init()
    }
    if ($('.js-study-highlights-illustration').length) {
        studyHighlightsHover.init()
    }
    if ($('.js-thematic-highlights-slider').length) {
        thematicHighlightsSlider.init()
    }
    if ($('.js-study-also-read-slider').length) {
        studyAlsoReadSlider.init()
    }
    if ($('.js-last-studies-slider').length) {
        lastStudiesSlider.init()
    }
    if ($('.js-photo-library-slider').length) {
        photoLibrarySlider.init()
    }
    if ($('.js-study-hero-item').length) {
        studyHero.init()
    }
    if ($('.js-don__input').length) {
        don.init()
    }
    if ($('.js-hero-filters').length) {
        filters.init()
    }
    if ($('.js-team-member').length) {
        team.init()
    }
    if ($('.js-textCloud-block').length) {
        textCloud.init()
    }
    if ($('.js-ajax-list').length) {
        showMoreAjax.init()
    }
    let $mobileShowMore = $(".js-mobile-show-more")
    if ($mobileShowMore.length) {
        $mobileShowMore.click(function(){
            $(".js-studyList__more--mobile").hide();
            $(".js-studyList__more").css({"display":"flex"});
            $(".js-studyList__cell-hideMobile").show();
            $(".js-studyList__cell-hideMobile").eq(0).find(".js-study-card").focus();
            $(".js-ajax-list-content").addClass("firstLoadDone");
            window.lazyLoadInstance.update();
        });
    }
    if ($('.js-sharer').length) {
        sharer.init()
    }
    if ($('.js-sidehero-hero').length) {
        sidehero.init()
    }
    if ($('.js-note').length) {
        note.init()
    }
    if ($('.js-homepageHero__container').length) {
        $('.js-homepageHero__container').slick({
            infinite: false,
            slidesToShow: 2,
            arrows: false,
            responsive: [
                {
                    breakpoint: 701,
                    settings: {
                        slidesToShow: 1.19,
                    },
                },
            ],
        })
    }
    if ($('.js-homepageFeed__showMore').length) {
        $('.js-homepageFeed__showMore').click(function () {
            $('.js-homepageFeed__toShow').slideDown()
            $(this).hide()
            $('.js-homepageFeed__toShow').find('a').eq(0).focus()
            setTimeout(function(){
                $(window).resize(); // force reflow
                window.lazyLoadInstance.update();
            }, 250);
        })
    }
    if ($('.js-inTheMediaListHome__mobileSlider').length) {
        $('.js-inTheMediaListHome__mobileSlider').slick({
            infinite: false,
            slidesToShow: 1,
            arrows: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1299,
                    // settings: 'unslick',

                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 701,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        })
    }
    
    if($(".js-hero-filters-select2").length){
        $(".js-hero-filters-select2").select2();
    }

    let $homepageHero__col_content = $(".js-homepageHero__col-content")
    if($homepageHero__col_content.length){
        function check_homepageHero__col_content_bigger_height(){
            let biggerheight = 0;
            $homepageHero__col_content.css({'min-height':0});
            for (var i = 0; i < $homepageHero__col_content.length; i++) {
                let height = $homepageHero__col_content.eq(i).outerHeight(true);
                if(height > biggerheight){
                    biggerheight = height;
                }
            }
            $homepageHero__col_content.css({'min-height':biggerheight});
        }
        check_homepageHero__col_content_bigger_height();
        $(window).resize(function(){
            check_homepageHero__col_content_bigger_height();
        });
    }

    $(".js-can_have_notes .section").each(function(){
        var $section = $(this)
        ,   sectionIndex = $section.attr("data-index")
        ,   $notesLink = $section.find(".js-note-backToText")
        ,   $sup = $section.find(".inner__content sup");
        for (var i = 0; i < $sup.length; i++) {
            $notesLink.eq(i).attr("id","note"+sectionIndex+"_"+i).attr("href", "#note"+sectionIndex+"_"+i+"_text");
            $sup.eq(i).html("<a class='note__goToNote js-note-goToNote js-scroll-anchor' href='#note"+sectionIndex+"_"+i+"' id='note"+sectionIndex+"_"+i+"_text'>"+$sup.eq(i).text()+"</a>")
        }
    });
    $("body").on('click', '.js-note-goToNote, .js-note-backToText', function(){
        $($(this).attr("href")).focus();
    });

    $("[src*='https://www.facebook.com/plugins/video.php']").wrap('<div class="mediaSection__content-video"></div>');


    if ($('.js-scrollNav').length) {
        // used on fondation page
        scrollNav.init()
    }
})
