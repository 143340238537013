const multiSelect = {

    init: function init() {

    	this.toggleMultiSelect();

    	this.onSelectChange();

    	this.updateCounter();

    	this.multiSelectReplace();
	},

	toggleMultiSelect: function toggleMultiSelect() {
		$(".js-multi-select-toggle").each(function(){
			let $this = $(this),
				$container = $this.parents(".js-multi-select"),
				$dropdown = $container.find(".js-multi-select-dropdown");

			$this.on("click", function(){
				if($container.hasClass("multi-select--is-active")){
					$container.removeClass("multi-select--is-active");
					$dropdown.slideUp(200);
				} else {
					$container.addClass("multi-select--is-active");
					$dropdown.slideDown(200);
				}
			});
			$(document).mouseup(function (e){
				if (!$container.is(e.target) && $container.has(e.target).length === 0)
				{
					$container.removeClass("multi-select--is-active");
					$dropdown.slideUp(200);
				}
			});
		});
	},

	onSelectChange: function onSelectChange() {
    	$(".js-multi-select").each( function() {
    		var $this = $(this),
				$checkbox = $this.find(".js-checkbox-input");

			$checkbox.on("change", function() {
				multiSelect.updateCounter($this);
			});

		});
	},

	updateCounter: function updateCounter($multiSelect) {
        var $multiSelect = $multiSelect||false
        if($multiSelect){
            var $checkboxCount = $multiSelect.find(".js-checkbox-input:checked").length
                ,	$placeholderCounter = $multiSelect.find(".js-multi-select-counter");

            if($checkboxCount > 0){
                $placeholderCounter.text("(" + $checkboxCount + ")");
            } else {
                $placeholderCounter.text("");
            }

        } else {
            $(".js-multi-select").each(function(){
                var $this = $(this)
                    ,	$checkboxCount = $this.find(".js-checkbox-input:checked").length
                    ,	$placeholderCounter = $this.find(".js-multi-select-counter");

                if($checkboxCount > 0){
                    $placeholderCounter.text("(" + $checkboxCount + ")");
                } else {
                    $placeholderCounter.text("");
                }
            });
        }
    },

    multiSelectReplace: function multiSelectReplace() {
        $('.js-multi-select-replace-link').on('click', function () {
        	var text = $(this).html();
        	var $multiselect = $(this).closest('.js-multi-select');
            $multiselect.find('.js-multi-select-replace-placeholder').html(text);
            $multiselect.removeClass('multi-select--is-active');
            $multiselect.find('.js-multi-select-dropdown').slideUp(200);
        });
    }

};

module.exports = multiSelect;