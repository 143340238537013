var note = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
        this.checkNoteLength()
    },

    bindUI: function () {
        this.ui.$noteButton = $('.js-note-button')
    },

    bindEvents: function () {
        this.ui.$noteButton.on('click', this.toggleNote.bind(this))
        $(window).on('resize', this.checkNoteLength.bind(this))
    },
    
    toggleNote: function (e) {
        $(e.currentTarget).closest('.js-note-item').toggleClass('is-active')
    },

    checkNoteLength: function () {
        $('.js-note-item').each((i, e) => {
            if ($(e).find('.js-note-text').height() > 50) {
                $(e).addClass('is-collapsed')
            } else {
                $(e).removeClass('is-collapsed')
            }
        })
    },
}

module.exports = note
