// Import Slick
var summary = {
    ui: {
        currentSection: 0,
        currentTitle: 0,
        currentSubtitle: 0,
    },

    init: function () {
        this.bindUI()
        this.bindEvents()
        this.stickySummary()

        if(this.ui.$summary.find(".js-summary-item-subtitle").length == 0){
            this.ui.$summaryMore.hide();
        }
    },

    bindUI: function () {
        this.ui.$summary = $('.js-summary')
        this.ui.$summarySelectText = $('.js-summary-select-text')
        this.ui.$summaryContainer = $('.js-summary-container')
        this.ui.$summaryContent = $('.js-summary-content')
        this.ui.$summaryItem = $('.js-summary-item')
        this.ui.$summaryMore = $('.js-summary-more')
        this.ui.$intersectingItems = $('.js-section-fullwidth, .js-sidebar-item, .js-hero')
        this.ui.$intersectingItems_withoutNotes = $('.js-section-fullwidth, .js-hero')
        this.ui.$sections = $('.section')
        this.ui.$sectionSumTitles = $('.js-summary-title')
        this.ui.$sectionSumSubtitles = $('.js-summary-subtitle')
        this.ui.$summaryItemTitles = $('.js-summary-item-title')
        this.ui.$summaryItemSubtitles = $('.js-summary-item-subtitle')
    },

    bindEvents: function () {
        this.ui.$summaryMore.on('click', this.summaryMore.bind(this))
        $(window).on('scroll resize', this.stickySummary.bind(this))
        $(window).on('click', this.toggleSummary.bind(this))
        this.ui.$summaryItem.on('click', (e) => this.scrollToSection(this, e))
    },

    toggleSummary: function (e) {
        if ($(e.target).hasClass('js-summary-select') || $(e.target).closest('.js-summary-select').length) {
            this.ui.$summaryContent.toggleClass('is-open')
        } else if ($(e.target).hasClass('js-summary-content') || $(e.target).closest('.js-summary-content').length) {
            return false
        } else if (this.ui.$summaryContent.hasClass('is-open')) {
            this.ui.$summaryContent.removeClass('is-open')
        }
    },

    summaryMore: function () {
        let $thisSumary = this.ui.$summary
        $thisSumary.toggleClass('is-unwrap')
        setTimeout(function(){
            if ($(".js-summary-item-subtitle.is-active").length > 0) {
                $thisSumary.find(".summary__list").animate({
                    scrollTop: $(".js-summary-item-subtitle.is-active")[0].offsetTop-10,
                },
                500);
            } else {
                $thisSumary.find(".summary__list").animate({
                    scrollTop: $(".js-summary-item-title.is-active")[0].offsetTop-10,
                },
                500);
            }
        },250)
    },

    stickySummary: function () {
        /* Controling the summary position and overlap */
        if (window.pageYOffset > this.ui.$summary.offset().top - 100) {
            if(this.ui.$summary.hasClass('is-fixed') === false) this.ui.$summary.addClass('is-fixed')
        } else if (this.ui.$summary.hasClass('is-fixed')) {
            this.ui.$summary.removeClass('is-fixed')
        }

        let $intersectingItemsList;
        // if (this.ui.$summary.hasClass('is-unwrap')) {
        //     $intersectingItemsList = this.ui.$intersectingItems_withoutNotes;
        // } else {
            $intersectingItemsList = this.ui.$intersectingItems;
        // }

        let sumY = this.ui.$summaryContainer[0].getBoundingClientRect().y
        let sumH = this.ui.$summaryContainer[0].getBoundingClientRect().height

        /* Summary displacement and visibility (only on desktop) */
        if (window.innerWidth > 1050) {
            let overlap = 0
            let overlapCount = 0
            let overlapHide = 0
            $intersectingItemsList.each((i, s) => {
                let secY = s.getBoundingClientRect().y
                let secH = s.getBoundingClientRect().height
                if ((sumY < secY + secH) && (sumH + sumY > secY)) {
                    overlapCount++
                    if (sumY < secY) {
                        overlap = -(sumY + sumH - secY)
                        return false
                    } else {
                        overlap = secY + secH - sumY
                        return false
                    }
                }
            })

            this.ui.$summaryContent.css('transform', 'translateY(' + overlap + 'px)')

            // We check again if overlap after the summary has been translated
            let sumInnerRect = this.ui.$summaryContent[0].getBoundingClientRect()
            $intersectingItemsList.each((i, s) => {
                let secRect = s.getBoundingClientRect()
                let overlap = !(
                    sumInnerRect.right < secRect.left
                    || sumInnerRect.left > secRect.right
                    || sumInnerRect.bottom < secRect.top
                    || sumInnerRect.top > secRect.bottom
                )
                if (overlap) overlapHide++;
            })

            if (overlapHide > 0) {
                if(this.ui.$summary.hasClass('is-hidden') === false) {
                    this.ui.$summary.addClass('is-hidden')
                }
            } else if (this.ui.$summary.hasClass('is-hidden')) {
                this.ui.$summary.removeClass('is-hidden')
            }
        } else if ($('.js-study-hero').length) {
            if (this.ui.$summary.hasClass('has-study') === false) {
                this.ui.$summary.addClass('has-study')
            }
        }


        /* Controling the current section */
        let halfscreenScrollTop = $(window).scrollTop() + (window.innerHeight/2);
        let thirdscreenScrollTop = $(window).scrollTop() + (window.innerHeight/3);

        let $currentSummaryTitle = $(this.ui.$summaryItemTitles[0]);
        this.ui.$sectionSumTitles.each((i, s) => {
            let secH = s.getBoundingClientRect().height
            let secOffsetTop = $(s).offset().top
            let sectionMiddle = secOffsetTop + (secH/2);
            if (halfscreenScrollTop >= sectionMiddle) {
                if (
                    $(s).data('index') !== undefined &&
                    $(s).data('index') !== this.ui.currentTitle
                ) {
                    this.ui.currentTitle = $(s).data('index')
                    $currentSummaryTitle = this.ui.$summaryItemTitles.filter((i, e) => {
                        return $(e).data('section') === this.ui.currentTitle
                    })
                }
            }
        })
        this.ui.$summaryItemTitles.removeClass('is-active')
        $currentSummaryTitle.addClass('is-active')
        this.ui.$summarySelectText.text($currentSummaryTitle.text())

        let $currentSummarySubtitle = false;
        this.ui.$sectionSumSubtitles.each((i, s) => {
            let secH = s.getBoundingClientRect().height
            let secOffsetTop = $(s).offset().top
            let sectionMiddle = secOffsetTop + (secH/2);
            if (thirdscreenScrollTop >= sectionMiddle) {
                if (
                    $(s).data('index') !== undefined
                ) {
                    this.ui.currentSubtitle = $(s).data('index')
                    $currentSummarySubtitle = this.ui.$summaryItemSubtitles.filter((i, e) => {
                        return $(e).data('section') === this.ui.currentSubtitle
                    })
                }
            }
        })
        this.ui.$summaryItemSubtitles.removeClass('is-active')
        if ($currentSummarySubtitle) {
            // This condition because if we highlight chapter 2, the last subchapter from chapter 1 must not be highlighted
            if ($currentSummaryTitle.data('section') < $currentSummarySubtitle.data('section')) {
                $currentSummarySubtitle.addClass('is-active')
                this.ui.$summarySelectText.text($currentSummarySubtitle.text())
            }
        }
    },

    scrollToSection: function (that, e) {
        /* Controling the scroll to section */
        let selectedIndex = $(e.currentTarget).data('section')
        let $selectedSection = that.ui.$sections
            .filter((i, e) => {
                return $(e).data('index') === selectedIndex
            })
            .first()
        if($selectedSection.length){
            let offsetTop = $selectedSection.offset().top;
            this.recursivScroll($selectedSection,offsetTop);
        }
    },
    // fix img loading and changing scroll position
    recursivScroll: function($selectedSection,offsetTop){
        $('html, body').animate(
            {
                scrollTop: offsetTop,
            },
            250,
            () => {
                if(offsetTop != $selectedSection.offset().top){
                    offsetTop = $selectedSection.offset().top;
                    this.recursivScroll($selectedSection, offsetTop);
                }
                this.ui.$summaryContent.removeClass('is-open')
            }
        );
    }
}

module.exports = summary
