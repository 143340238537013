var inTheMediaCardHover = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$inTheMediaCard = $('.js-in-the-media-card')
        this.ui.$currentInTheMediaCard = false
    },

    bindEvents: function () {
        // this.ui.$inTheMediaCard.on('mouseenter', this.cardEnter.bind(this))
        // this.ui.$inTheMediaCard.on('mousemove', (e) => {
        //     window.requestAnimationFrame(() => this.cardHover(e))
        // })
        // this.ui.$inTheMediaCard.on('mouseleave', this.cardLeave.bind(this))
        
        $("body").on('mouseenter', '.js-in-the-media-card', this.cardEnter.bind(this))
        $("body").on('mousemove', '.js-in-the-media-card', (e) => {
            window.requestAnimationFrame(() => this.cardHover(e))
        })
        $("body").on('mouseleave', '.js-in-the-media-card', this.cardLeave.bind(this))
    },

    cardEnter: function (e) {
        e.stopPropagation()
        this.ui.$currentInTheMediaCard = $(e.currentTarget)[0]
    },

    cardHover: function (e) {
        e.stopPropagation()
        if (this.ui.$currentInTheMediaCard === false) {
            return false
        }
        let $this = $(this.ui.$currentInTheMediaCard)
        let $targetImage = $this.find('.js-in-the-media-card-image')

        let mouseX = e.clientX - $this[0].getBoundingClientRect().left
        let mouseY = e.clientY - $this[0].getBoundingClientRect().top
        let width = $this[0].getBoundingClientRect().width
        let height = $this[0].getBoundingClientRect().height

        let translateX = ((mouseX - width / 2) / (width / 2)) * (width / 50)
        let translateY = ((mouseY - height / 2) / (height / 2)) * (height / 50)

        $targetImage &&
            $targetImage.css({
                transform: 'translate3d(' + translateX + 'px, ' + translateY + 'px, 0) scale(1.04)',
            })
    },

    cardLeave: function (e) {
        e.stopPropagation()
        let $this = $(this.ui.$currentInTheMediaCard)
        let $targetImage = $this.find('.js-in-the-media-card-image')
        $targetImage &&
            $targetImage.css({
                transform: '',
            })
        this.ui.$currentInTheMediaCard = false
    },
}

module.exports = inTheMediaCardHover
