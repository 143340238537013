// Import Slick
import '../../../gulp/node_modules/slick-carousel'

var photoLibrarySlider = {
    ui: {
        isPlaying: true,
        slideSpeed: 800,
        slideAutoplaySpeed: 5000,
    },

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$slider = $('.js-photo-library-slider')
        this.ui.$prev = $('.js-photo-library-prev')
        this.ui.$next = $('.js-photo-library-next')
    },

    bindEvents: function () {
        this.ui.$prev.on('click', this.onClickPrev.bind(this))
        this.ui.$next.on('click', this.onClickNext.bind(this))
        this.ui.$slider.on('init', this.onSliderInit.bind(this))
        this.ui.$slider.on('swipe', this.onSwipe.bind(this))
        this.ui.$slider.on('beforeChange', this.onSlideBeforeChange.bind(this))
        this.ui.$slider.on('afterChange', this.onSlideAfterChange.bind(this))

        this.ui.$slider.slick({
            infinite: true,
            slidesToShow: 1,
            speed: this.ui.slideSpeed,
            arrows: false,
            autoplay: true,
            autoplaySpeed: this.ui.slideAutoplaySpeed,
            pauseOnHover: false,
        })
    },

    onSwipe: function (e) {
        if (this.ui.isPlaying) {
            this.ui.isPlaying = false
            $(e.target).slick('slickPause')
        }
    },

    onClickPrev: function (e) {
        let $slider = $(e.target).closest('.js-photo-library').find('.js-photo-library-slider')
        $slider.slick('slickPrev')
        if (this.ui.isPlaying) {
            this.ui.isPlaying = false
            $slider.slick('slickPause')
        }
    },

    onClickNext: function (e) {
        let $slider = $(e.target).closest('.js-photo-library').find('.js-photo-library-slider')
        $slider.slick('slickNext')
        if (this.ui.isPlaying) {
            this.ui.isPlaying = false
            $slider.slick('slickPause')
        }
    },

    onSliderInit: function (e, slick) {
        $(e.target)
            .closest('.js-photo-library')
            .find('.js-photo-library-timer')
            .addClass('is-animating')
    },

    onSlideBeforeChange: function (e, slick, currentSlide, nextSlide) {
        let $library = $(e.target).closest('.js-photo-library')
        let $counter = $library.find('.js-photo-library-counter')
        let $timer = $library.find('.js-photo-library-timer')
        $library.find('.js-photo-library-text').removeClass('is-active')
        $library.find(`.js-photo-library-text[data-slide='${nextSlide}']`).addClass('is-active')
        $library.find(`.js-photo-library-sharer`).removeClass('is-active')
        $library.find(`.js-photo-library-sharer[data-slide='${nextSlide}']`).addClass('is-active')
        let nextSlideCount = ('0' + (nextSlide + 1)).slice(-2)
        $counter.text(nextSlideCount);
        if ($timer.hasClass('is-reseting') === false) {
            $timer.addClass('is-reseting')
            setTimeout(() => {
                $timer.removeClass('is-reseting is-animating')
            }, this.ui.slideSpeed - 100)
        }

    },

    onSlideAfterChange: function (e, slick, currentSlide) {
        if (this.ui.isPlaying) {
            $(e.target)
                .closest('.js-photo-library')
                .find('.js-photo-library-timer')
                .addClass('is-animating')
        }
    },
}

module.exports = photoLibrarySlider
