// Import Slick
import '../../../gulp/node_modules/slick-carousel'

var studyAlsoReadSlider = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
        this.checkSliderLength()
    },

    bindUI: function () {
        this.ui.$section = $('.js-study-also-read')
        this.ui.$slider = $('.js-study-also-read-slider')
        this.ui.$prev = $('.js-study-also-read-prev')
        this.ui.$next = $('.js-study-also-read-next')
    },

    bindEvents: function () {
        this.ui.$slider.slick({
            infinite: false,
            slidesToShow: 3,
            speed: 800,
            arrows: false,
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1.15,
                    },
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        })
        this.ui.$prev.on('click', this.onClickPrev.bind(this))
        this.ui.$next.on('click', this.onClickNext.bind(this))
        this.ui.$slider.on('beforeChange', this.onSlideChange.bind(this))
    },

    onClickPrev: function (e) {
        $(e.target)
            .closest('.js-study-also-read')
            .find('.js-study-also-read-slider')
            .slick('slickPrev')
    },

    onClickNext: function (e) {
        $(e.target)
            .closest('.js-study-also-read')
            .find('.js-study-also-read-slider')
            .slick('slickNext')
    },

    onSlideChange: function (e, slick, currentSlide, nextSlide) {
        let showingSlides = 3
        if (window.innerWidth < 700) {
            showingSlides = 1
        } else if (window.innerWidth < 1300) {
            showingSlides = 2
        }
        if (nextSlide <= 0) {
            $(e.target)
                .closest('.js-study-also-read')
                .find('.js-study-also-read-prev')
                .addClass('is-disabled')
        } else {
            $(e.target)
                .closest('.js-study-also-read')
                .find('.js-study-also-read-prev')
                .removeClass('is-disabled')
        }

        if (nextSlide >= slick.slideCount - showingSlides) {
            $(e.target)
                .closest('.js-study-also-read')
                .find('.js-study-also-read-next')
                .addClass('is-disabled')
        } else {
            $(e.target)
                .closest('.js-study-also-read')
                .find('.js-study-also-read-next')
                .removeClass('is-disabled')
        }
    },

    checkSliderLength: function (e) {
        let showingSlides = 3
        if (window.innerWidth < 700) {
            showingSlides = 1
        } else if (window.innerWidth < 1300) {
            showingSlides = 2
        }
        if (this.ui.$section.find('.js-study-also-read-slide').length <= showingSlides) {
            this.ui.$section.find('.js-study-also-read-next').addClass('is-disabled')
        }
    }
}

module.exports = studyAlsoReadSlider
