const browsetClassHelpers = {
	ui: {},

	init: function() {
		// DETECT IE11 or EDGE
		let ua = window.navigator.userAgent;
		let isIE = 0;
		let msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			isIE++;
		}
		let trident = ua.indexOf('Trident/');
		if (trident > 0) {
			isIE++;
		}
		let edge = ua.indexOf('Edge/');
		if (edge > 0) {
			isIE++;
		}
		if (isIE > 0) {document.documentElement.className+= " isIE";}

		// DETECT IOS
		let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		if (iOS) {document.documentElement.className+= " isIOS";}
	}

};

module.exports = browsetClassHelpers;
