var studyHighlightsHover = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$studyHighlights = $('.js-study-highlights-illustration')
        this.ui.$currentStudyHighlights = false
    },

    bindEvents: function () {
        this.ui.$studyHighlights.on('mouseenter', this.cardEnter.bind(this))
        this.ui.$studyHighlights.on('mousemove', (e) => {
            window.requestAnimationFrame(() => this.cardHover(e))
        })
        this.ui.$studyHighlights.on('mouseleave', this.cardLeave.bind(this))
    },

    cardEnter: function (e) {
        e.stopPropagation()
        this.ui.$currentStudyHighlights = $(e.currentTarget)[0]
    },

    cardHover: function (e) {
        e.stopPropagation()
        if (this.ui.$currentStudyHighlights === false) {
            return false
        }
        let $this = $(this.ui.$currentStudyHighlights)
        let $targetCover = $this.find('.js-study-highlights-cover')

        let mouseX = e.clientX - $this[0].getBoundingClientRect().left
        let mouseY = e.clientY - $this[0].getBoundingClientRect().top
        let width = $this[0].getBoundingClientRect().width
        let height = $this[0].getBoundingClientRect().height

        let translateX = ((mouseX - width / 2) / (width / 2)) * (width / 50)
        let translateY = ((mouseY - height / 2) / (height / 2)) * (height / 50)

        $targetCover &&
            $targetCover.css({
                transform:
                    'translate3d(' +
                    translateX +
                    'px, ' +
                    translateY +
                    'px, 0) scale(1.04)',
            })
    },

    cardLeave: function (e) {
        e.stopPropagation()
        let $this = $(this.ui.$currentStudyHighlights)
        let $targetCover = $this.find('.js-study-highlights-cover')
        $targetCover &&
            $targetCover.css({
                transform: 'translate3d(0,0,0) scale(1)',
            })
        this.ui.$currentStudyHighlights = false
    },
}

module.exports = studyHighlightsHover
