var sidehero = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
        this.sectionResize()
    },

    bindUI: function () {
        this.ui.$sideHero = $('.js-sidehero-hero')
        this.ui.$sideHeroSection = $('.js-sidehero-section')
    },

    bindEvents: function () {
        $(window).resize(() => this.sectionResize())
    },

    sectionResize: function () {
        if (this.ui.$sideHeroSection.length > 0) {
            this.ui.$sideHeroSection.css('minHeight', this.ui.$sideHero.height() + 50)
        }
    },
}

module.exports = sidehero
