const newsletterPopin = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$popin = $('.js-popin')
        this.ui.$underlay = $('.js-popin-underlay')
        this.ui.$close = $('.js-popin-close')
        this.ui.$open = $('.js-popin-open')
    },

    bindEvents: function () {
        this.ui.$underlay.on('click', this.hidePopin.bind(this))
        this.ui.$close.on('click', this.hidePopin.bind(this))
        this.ui.$open.on('click', this.showPopin.bind(this))
    },

    hidePopin: function () {
        this.ui.$popin.removeClass('is-active')
    },

    showPopin: function (e) {
        let $targetPopin = this.ui.$popin.filter(function () {
            return $(this).data('name') == $(e.target).data('target')
        })
        $targetPopin.addClass('is-active')
    },
}

module.exports = newsletterPopin
