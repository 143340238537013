// Import Slick
import '../../../gulp/node_modules/slick-carousel'

var lastStudiesSlider = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$slider = $('.js-last-studies-slider')
    },

    bindEvents: function () {
        this.ui.$slider.slick({
            lazyLoad: 'ondemand',
            infinite: false,
            slidesToShow: 4.2,
            slidesToScroll: 3,
            speed: 800,
            prevArrow: '<button type="button" class="slick-prev"><svg class="icon icon-arrow-left"><use xlink:href="#icon-arrow-left"></use></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg class="icon icon-arrow-right"><use xlink:href="#icon-arrow-right"></use></svg></button>',
            responsive: [
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 3.1,
                        slidesToScroll: 2
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2.1,
                        slidesToScroll: 1
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1.3,
                        slidesToScroll: 1
                    },
                },
            ],
        })
    },
}

module.exports = lastStudiesSlider
