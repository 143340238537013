const heroAnimate = {
	ui: {
        heroOpacity: 1,
        animEnded: false
    },

	init: function() {
		this.bindUI();
        this.bindEvents();
        this.animInit();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$header = $(".js-header-animate");
		this.ui.$hero = $(".js-hero-animate");
		this.ui.$heroWrapper = $(".js-hero-wrapper");
		this.ui.$heroHeading = $(".js-hero-heading");
	},

	bindEvents: function bindEvents() {
        this.ui.$window.on('scroll resize', this.heroOnScroll.bind(this));
	},

	animInit: function(e) {
        this.ui.$hero.removeClass('is-hidden')
        setTimeout(() => {
            this.heroOnScroll()
        }, 1000)
        setTimeout(() => {
            this.ui.animEnded = true
        }, 2000)
    },
    
    heroOnScroll: function(e) {
        let heroHeight = this.ui.$hero.outerHeight() - (0.3 * this.ui.$hero.outerHeight())
        let top = $(document).scrollTop()
        let winWidth = $(window).width()
        if (top > heroHeight) {
            if (winWidth > 1050) {
                // this.ui.$heroWrapper.css('transition', '0.1s')
                this.ui.$heroHeading.css('opacity', 1)
                this.ui.$heroWrapper.css('opacity', 0)
            } else {
                // this.ui.$heroHeading.css('transition', '0.1s')
                this.ui.$heroWrapper.css('opacity', 1)
                this.ui.$heroHeading.css('opacity', 0)
            }
        } else if (this.ui.animEnded == true) {
            this.ui.heroOpacity = Math.round((heroHeight+200 - top) / heroHeight *1000) / 1000
            if (winWidth > 1050) {
                // this.ui.$heroWrapper.css('transition', '0.1s')
                this.ui.$heroHeading.css('opacity', 1)
                this.ui.$heroWrapper.css('opacity', this.ui.heroOpacity)
            } else {
                // this.ui.$heroHeading.css('transition', '0.1s')
                this.ui.$heroWrapper.css('opacity', 1)
                this.ui.$heroHeading.css('opacity', this.ui.heroOpacity)
            }
        }
        if ($(window).width() > 510) {
            if (top > (this.ui.$hero.outerHeight() - this.ui.$header.outerHeight() * 2) - 50) {
                if (this.ui.$header.hasClass('is-hidden') == false) {
                    this.ui.$header.addClass('is-hidden')
                }
            } else {
                if (this.ui.$header.hasClass('is-hidden')) {
                    this.ui.$header.removeClass('is-hidden')
                }
            }
        } else {
            if (top > (this.ui.$hero.outerHeight() / 4) - 70) {
                if (this.ui.$header.hasClass('is-hidden') == false) {
                    this.ui.$header.addClass('is-hidden')
                }
            } else {
                if (this.ui.$header.hasClass('is-hidden')) {
                    this.ui.$header.removeClass('is-hidden')
                }
            }
        }
        
    },

};

module.exports = heroAnimate;
