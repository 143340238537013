var newsletterPopin = {
    ui: {},
    openfromHeader: false,
    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$open = $('.js-newsletter-popin-open')
        this.ui.$close = $('.js-newsletter-popin-close')
        this.ui.$validate = $('.js-newsletter-popin-validate')
        this.ui.$link = $('.js-study-download-link')
        this.ui.$popin = $('.js-popin')
    },

    bindEvents: function () {
        this.ui.$open.on('click', this.showPopin.bind(this))
        this.ui.$close.on('click', this.hidePopin.bind(this))
        this.ui.$validate.on('click', this.validate.bind(this))
    },

    showPopin: function (e) {
        let $targetPopin = this.ui.$popin.filter(function () {
            return $(this).data('name') == $(e.currentTarget).data('target')
        })
        if($(e.currentTarget).hasClass('js-newsletter-popin-alwayOpen')){
            $targetPopin.addClass('is-active')
            this.openfromHeader = true;
        }else{
            if (sessionStorage.getItem('newsletterPopin') ) {
                $('.js-study-hero-item.is-active .js-study-download-link')[0].click()
            } else {
                $targetPopin.addClass('is-active')
                sessionStorage.setItem('newsletterPopin', true)
            }
        }
    },

    hidePopin: function (e) {
        this.ui.$popin.removeClass('is-active')
        if(this.openfromHeader != true){
            $('.js-study-hero-item.is-active .js-study-download-link')[0].click()
        }else{
            this.openfromHeader = false;
        }
    },

    validate: function (e) {
        $('.js-study-hero-item.is-active .js-study-download-link')[0].click()
    },
}

module.exports = newsletterPopin
