const header = {
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $("body");

		this.ui.$header = $(".js-header");

		this.ui.$menuToggle = $(".js-toggle-menu");
		this.ui.$menuContainer = $(".js-header");
		this.ui.$menuNav = $(".js-mobile-nav");
		this.ui.switchlang = $('.js-switchlang-link')
	},

	bindEvents: function bindEvents() {
		// this.ui.$window.on('scroll', this.menuOnScroll.bind(this));
		
		this.ui.$menuNav.on('click', this.stopPropagation.bind(this));
		this.ui.$menuToggle.on('click', this.toggleMenuMobile.bind(this));
		this.ui.switchlang.on('click', this.switchLang.bind(this));
	},

	switchLang: function(e) {
		this.ui.switchlang.removeClass('active');
		$(e.currentTarget).addClass('active');
	},

	menuOnScroll: function(e) {
		let self = this;
		let $this = $(e.target);
		
		if($this.scrollTop() > 50 && !this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").addClass("header--smaller");
		}
		if($this.scrollTop() === 0 && this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").removeClass("header--smaller");
		}
	},
	stopPropagation: function(e) {
		e.stopPropagation();
	},
	toggleMenuMobile: function(e) {
		e.stopPropagation();
		let $this = this.ui.$menuToggle;
		
		if($this.hasClass("active")){
			this.ui.$body.removeClass("no-scroll");
			$this.removeClass("active");
			this.ui.$menuContainer.removeClass("active");
			this.ui.$menuNav.removeClass("active");
		} else {
			this.ui.$body.addClass("no-scroll");
			$this.addClass("active");
			this.ui.$menuContainer.addClass("active");
			this.ui.$menuNav.addClass("active");
		}
	}

};

module.exports = header;
