// Import Slick
var tabs = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$tabs = $('.js-tabs')
        this.ui.$tabsHeader = $('.js-tabs-header')
        this.ui.$tabsTitle = $('.js-tabs-title')
        this.ui.$tabsContent = $('.js-tabs-content')
        this.ui.currentTabIndex = 0
    },

    bindEvents: function () {
        this.ui.$tabsTitle.on('click', this.changeTab.bind(this))
    },

    changeTab: function (e) {
        /* Scroll to the clicked tab title on mobile */
        let scrollPosition = $(e.currentTarget).position().left
        scrollPosition > 0 && scrollPosition < 50 && (scrollPosition = 0)
        this.ui.$tabsHeader.animate({ scrollLeft: scrollPosition }, 300)

        /* Set the current selected tab title on active */
        this.ui.$tabsTitle.removeClass('is-active')
        $(e.currentTarget).addClass('is-active')

        /* Set the current selected tab on active */
        this.ui.currentTabIndex = $(e.currentTarget).data('title-index')
        this.ui.$tabsContent.removeClass('is-active')
        let $selectedTab = this.ui.$tabsContent.filter((i, e) => {
            return $(e).data('content-index') == this.ui.currentTabIndex
        })
        $selectedTab.addClass('is-active')
    },
}

module.exports = tabs
