// this use jquery ui tooltip system

const ssTooltip = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
		
		this.bindTooltips();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$tooltip = $('.js-tooltip');
	},

	bindEvents: function() {
		this.ui.$document.on('click touchend', this.closeTooltips.bind(this));
	},
	

	bindTooltips: function() {
		$('.js-tooltip').each(function( index ){
			let $this = $(this);
			let tooltipPostMy;
			let tooltipPostAt;
			
			if ($this.attr('data-tooltip-position') === 'top') {
				tooltipPostMy = 'center bottom-20';
				tooltipPostAt = 'center bottom';
			} else if ($this.attr('data-tooltip-position') === 'left') {
				tooltipPostMy = 'right-5 center';
				tooltipPostAt = 'left center';
			} else if ($this.attr('data-tooltip-position') === 'right') {
				tooltipPostMy = 'left+5 center';
				tooltipPostAt = 'right center';
			} else if ($this.attr('data-tooltip-position') === 'bottom') {
				tooltipPostMy = 'center top+5';
				tooltipPostAt = 'center bottom';
			} else {
				tooltipPostMy = 'center top+5';
				tooltipPostAt = 'center bottom';
			}
			
			$this.tooltip({
				classes: {
					"ui-tooltip-content": "tooltip"
				},
				position: {
					my: tooltipPostMy,
					at: tooltipPostAt,
					using: function( position, feedback ) {
						let arrowClass = '';
						if (['top', 'left', 'right', 'bottom'].includes(feedback[feedback.important])) {
							arrowClass = 'arrow-is-' + feedback[feedback.important];
						} else {
							arrowClass = 'arrow-is-top';
						}
						$( this ).css(position)
							.find('.tooltip')
							.addClass( arrowClass );
					}
				}
			});
		});
	},

	closeTooltips: function(e){
		$('.js-tooltip').each(function( index ){
			$(this).tooltip("option", "hide");
		});
	},
};

module.exports = ssTooltip;