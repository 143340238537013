var sharer = {
    ui: {
        copyTooltip: false,
    },

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$sharerOpen = $('.js-sharer-open')
        this.ui.$sharerClose = $('.js-sharer-close')
        this.ui.$sharerCopy = $('.js-sharer-copy')
        this.ui.$sharerCopyTooltip = $('.js-sharer-copy-tooltip')
    },

    bindEvents: function () {
        this.ui.$sharerOpen.on('click', this.sharerOpen.bind(this))
        this.ui.$sharerClose.on('click', this.sharerClose.bind(this))
        this.ui.$sharerCopy.on('click', this.sharerCopy.bind(this))
        this.ui.$sharerCopy.on('mouseout', this.resetCopyTooltip.bind(this))
    },

    sharerOpen: function (e) {
        $(e.currentTarget).closest('.js-sharer').addClass('is-active')
    },

    sharerClose: function (e) {
        $(e.currentTarget).closest('.js-sharer').removeClass('is-active')
    },

    sharerCopy: function (e) {
        let $sharerCopyInput = $(e.currentTarget).find('.js-sharer-copy-input')
        let $sharerCopyTooltip = $(e.currentTarget).find('.js-sharer-copy-tooltip')
        if (this.ui.copyTooltip === false) {
            this.ui.copyTooltip = $sharerCopyTooltip.text()
            $sharerCopyTooltip.text('Lien copié !')
        }
        $sharerCopyInput[0].select()
        $sharerCopyInput[0].setSelectionRange(0, 99999)
        document.execCommand('copy')
    },

    resetCopyTooltip: function () {
        if (this.ui.copyTooltip !== false) {
            this.ui.$sharerCopyTooltip.text(this.ui.copyTooltip)
            this.ui.copyTooltip = false
        }
    },
}

module.exports = sharer
