var scrollAnchor = {
	
	ui: {},

	initialOffset: 10,
	addToInitialOffset: this && this.initialOffset,

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);

		// this.ui.$linkScrollAnchor = $('a.js-scroll-anchor');
	},

	bindEvents: function() {
		// this.ui.$linkScrollAnchor.on('click', this.onClickAnchor.bind(this));
		$('body').on('click', 'a.js-scroll-anchor', this.onClickAnchor.bind(this));
	},

	onClickAnchor: function(e) {
		let self = this;
		let $this = $(e.target);

		e.preventDefault();
		var wpAdminHeight = 0;
		if($("#wpadminbar").length){
			wpAdminHeight = $("#wpadminbar").height();
		}
		var scrollNavHeight = 0;
		if($(".js-scrollNav").length){
			scrollNavHeight = $(".js-scrollNav").height()-wpAdminHeight;
		}
		if (this.ui.$window.width() < 1080) {
			var heroContainerHeight = 0;
			if($(".js-study-hero-container").length){
				heroContainerHeight = $(".js-study-hero-container").height();
			}
			var summaryContainerHeight = 0;
			if($(".js-summary-container").length){
				summaryContainerHeight = $(".js-summary-container").height();
			}
			this.addToInitialOffset = scrollNavHeight + wpAdminHeight + heroContainerHeight + summaryContainerHeight;

		} else {
			this.addToInitialOffset = wpAdminHeight;
		}
		$('html, body').animate({ 
			scrollTop: $($this.attr('href')).offset().top - self.addToInitialOffset 
		}, 300);
	},

};

module.exports = scrollAnchor;
