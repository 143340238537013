var studyCardHover = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$studyCard = $('.js-study-card')
        this.ui.$currentStudyCard = false
    },

    bindEvents: function () {
        // $("body").on('mouseenter', '.js-study-card', this.cardEnter.bind(this))
        // $("body").on('mousemove', '.js-study-card', (e) => {
        //     window.requestAnimationFrame(() => this.cardHover(e))
        // })
        // $("body").on('mouseleave', '.js-study-card', this.cardLeave.bind(this))
        
        $("body").on('mouseenter', '.js-study-card', this.cardEnter.bind(this))
        $("body").on('mousemove', '.js-study-card', (e) => {
            window.requestAnimationFrame(() => this.cardHover(e))
        })
        $("body").on('mouseleave', '.js-study-card', this.cardLeave.bind(this))
    },

    cardEnter: function (e) {
        e.stopPropagation()
        this.ui.$currentStudyCard = $(e.currentTarget)[0]
    },

    cardHover: function (e) {
        e.stopPropagation()
        if (this.ui.$currentStudyCard === false) {
            return false
        }
        let $this = $(this.ui.$currentStudyCard)
        let $targetCover = $this.find('.js-study-card-cover')

        let mouseX = e.clientX - $this[0].getBoundingClientRect().left
        let mouseY = e.clientY - $this[0].getBoundingClientRect().top
        let width = $this[0].getBoundingClientRect().width
        let height = $this[0].getBoundingClientRect().height

        let translateX = ((mouseX - width / 2) / (width / 2)) * (width / 50)
        let translateY = ((mouseY - height / 2) / (height / 2)) * (height / 50)

        $targetCover &&
            $targetCover.css({
                transform:
                    'translate3d(' +
                    translateX +
                    'px, ' +
                    translateY +
                    'px, 0) scale(1.04)',
            })
    },

    cardLeave: function (e) {
        e.stopPropagation()
        let $this = $(this.ui.$currentStudyCard)
        let $targetCover = $this.find('.js-study-card-cover')
        $targetCover &&
            $targetCover.css({
                transform: 'translate3d(0,0,0) scale(1)',
            })
        this.ui.$currentStudyCard = false
    },
}

module.exports = studyCardHover
