const headerSearch = {

    ui: {
        ajax: null
    },

    searchHistory: "",

    init: function init() {
        this.bindUI();
        this.bindEvents();

        this.getHistory();
        this.buildHistoryHtml();
    },

    bindUI: function bindUI() {
        this.ui.$header = $('.js-header');
        this.ui.$headerSearchForm = $('.js-headerSearchForm');
        this.ui.$headerSearchInput = $('.js-headerSearchForm__input');
        this.ui.$headerSearchResult = $('.js-headerSearchForm__result');
        this.ui.$headerSearchSubmit = $('.js-headerSearchForm__submit');
        this.ui.$headerSearchSubmitCount = $('.js-headerSearchForm__submit-count');
        this.ui.$openSearch = $('.js-headerSearch__open');
        this.ui.$closeSearch = $('body, .js-headerSearchForm__close');

        this.ui.$headerSearchHistory = $(".js-search-history");
        this.ui.$historyList = $(".js-headerSearchForm__history-list");
        this.ui.$historyItemToClone = $(".js-headerSearchForm__history-item-clone");
        this.ui.$headerRemoveSearchHistory = $(".js-headerSearchForm__history-remove");
    },

    bindEvents: function bindEvents() {
        this.ui.$headerSearchForm.on("click", function(e){e.stopPropagation();});
        this.ui.$openSearch.on('click', this.openSearch.bind(this));
        this.ui.$closeSearch.on('click', this.closeSearch.bind(this));

        this.ui.$headerRemoveSearchHistory.on('click', this.removeHistory.bind(this));

        this.ui.$headerSearchInput.on("keyup", this.searchAjax.bind(this));
        this.ui.$headerSearchForm.on("submit", this.submitSearch.bind(this));
    },

    
    submitSearch: function(e) {
        this.saveHistory();
    },
    setHistoryCookie: function(value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = "searchHistory"+"="+value+expires+"; path=/";
    },
    getHistoryCookie: function() {
        var nameEQ = "searchHistory" + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    getHistory: function() {
        // this.searchHistory = localStorage.getItem('searchHistory');
        this.searchHistory = this.getHistoryCookie();
        if(this.searchHistory != null){
            this.searchHistory = this.searchHistory.split(',');
        }else{
            this.searchHistory=[];
        }
    },
    saveHistory: function(e) {
        // e.preventDefault();
        let searchText = self.ui.$headerSearchInput.val();
        //if((myarr.indexOf("turtles") == -1){// si on a pas déjà dans l'historique
            this.searchHistory.unshift(searchText);//ajoute en début de tableau
            this.setHistoryCookie(this.searchHistory.toString());
        //}
        // localStorage.setItem('searchHistory', this.searchHistory.toString());
    },
    buildHistoryHtml: function() {
        if(this.searchHistory.length > 0 && this.searchHistory[0] != ""){
            var historyItemHref = this.ui.$historyItemToClone.find(".js-headerSearchForm__history-item-link").attr("href");
            for (var i = 0; i < this.searchHistory.length; i++) {
                var $historyItemNew = this.ui.$historyItemToClone.clone().removeClass("js-headerSearchForm__history-item-clone");
                var $historyItemLink = $historyItemNew.find(".js-headerSearchForm__history-item-link");
                $historyItemLink.attr("href", historyItemHref+this.searchHistory[i]);
                $historyItemLink.text(this.searchHistory[i]);
                this.ui.$historyList.append($historyItemNew);
            }
        }else{
            this.ui.$headerSearchHistory.hide();
        }
    },
    removeHistory: function(){
        this.searchHistory=[];
        this.setHistoryCookie("",-1);
        this.buildHistoryHtml();
    },
    showHistory: function(){
        if(this.searchHistory.length > 0 && this.searchHistory[0] != ""){
            this.ui.$headerSearchHistory.show();
        }else{
            this.ui.$headerSearchHistory.hide();
        }
    },


    openSearch: function openSearch(e) {
        e.preventDefault();
        e.stopPropagation();
        $("body, html").toggleClass("no-scroll");
        this.ui.$openSearch.toggleClass("is-active");
        this.ui.$headerSearchForm.toggleClass("is-active");
        this.ui.$header.toggleClass('is-open');
        if (this.ui.$header.hasClass('is-open')) {
            var self = this;
            setTimeout(function(){ // fix focus bug
                self.ui.$headerSearchInput.focus();
            },50);
        }
    },


    closeSearch: function closeSearch() {
        this.ui.$openSearch.removeClass("is-active");
        this.ui.$headerSearchForm.removeClass("is-active");
        $("body, html").removeClass("no-scroll");
    },

    searchTimeout: false,
    searchAjax: function searchAjax(e){
        e.preventDefault();
        e.stopPropagation();

        switch(e.keyCode){
            case 9 : // echap
            case 13 : // enter
            case 16 : // shift
            case 17 : // ctrl
            case 18 : // alt
            case 27 : // escape
            case 37 : // arrow
            case 38 : // arrow
            case 39 : // arrow
            case 40 : // arrow
                return;
            break;
            default:
            break;
        }

        self = this;
        let searchText = self.ui.$headerSearchInput.val();
        
        if(self.ui.ajax != null){
            self.ui.ajax.abort();
        }

        var size  = searchText.length;
        self.ui.$headerSearchInput.attr("size", size);

        clearTimeout(this.searchTimeout);
        if(searchText.length == 0){
            self.ui.$headerSearchInput.removeClass("hasValue");            
        }else{
            self.ui.$headerSearchInput.addClass("hasValue");
        }
        if(searchText.length < 3){
            this.ui.$headerSearchResult.html("").hide();
            this.ui.$headerSearchSubmit.hide();
            this.ui.$headerSearchSubmitCount.html("");
            this.showHistory();
            return;
        }
        self.ui.$headerSearchSubmit.hide();
        self.ui.$headerSearchForm.addClass("is-loading");

        if(this.ui.$headerSearchForm.hasClass("integration")){
            var ajaxUrl = "ajax-search-autocomplete.html";
        }else{
            var ajaxUrl = ajax_object.ajax_url;
        }
        self.searchTimeout = setTimeout(function(){
            self.ui.ajax = $.ajax({
                method: "POST",
                url: ajaxUrl,
                data: {
                    'action': 'get_autocomplete_search',
                    "search_terms": searchText,
                    'is_ajax': true,
                    'is_autocomplete': true,  
                }
            })
            .done(function(rep) {
                self.ui.$headerSearchHistory.hide();
                if(rep!="" && $.trim(rep) != ""){
                    var $rep = $(rep);
                    self.ui.$headerSearchResult.html($rep).show();
                    self.ui.$headerSearchSubmit.show();
                    self.ui.$headerSearchSubmitCount.html($rep.attr("data-count"));
                }
               self.ui.$headerSearchForm.removeClass("is-loading");
            });
        },500);
    }

};

module.exports = headerSearch;