var filters = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$filtersButton = $('.js-hero-filters-trigger')
        this.ui.$filtersSelect = $('.js-hero-filters-select')
        this.ui.$filtersSpinner = $('.js-filters-spinner')
    },

    bindEvents: function () {
        this.ui.$filtersButton.on('click', this.toggleFilters.bind(this))
        this.ui.$filtersSelect.on('change', this.redirectFilters.bind(this))
    },

    toggleFilters: function (e) {
        $(e.currentTarget).closest('.js-hero-filters').toggleClass('is-active')
    },

    redirectFilters: function (e) {
        if ($(e.currentTarget).val()) {
            this.ui.$filtersSpinner.addClass('is-active')
            window.location.href = $(e.currentTarget).val()
        }
    },
}

module.exports = filters
