// used on fondation page
const scrollNav = {
	
	ui: {},

	activeSection: 0,

	init: function() {
		this.bindUI();
		this.bindEvents();
		
	},

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);

		this.ui.$scrollNav = $('.js-scrollNav');
		this.ui.$scrollNavLink = $('.js-scrollNav-link');
		this.ui.$scrollNavLinkReversed = this.ui.$scrollNavLink.get().reverse();
		this.ui.$scrollNavContainer = $('.js-scrollNav-container');
	},

	bindEvents: function() {
		this.ui.$window.on('scroll', this.handleScroll.bind(this));
	},
	
	handleScroll: function(e){
		let $this = $(e.target);
		// if ($this.scrollTop() >= this.ui.$scrollNavContainer.offset().top) {
		if ($this.scrollTop() >= ($(".hero").outerHeight() - 90)) {
			this.ui.$scrollNav.addClass("fixed")
		} else {
			this.ui.$scrollNav.removeClass("fixed")
		}
		this.updateNavHighlight();
	},

    updateNavHighlight: function() {
		let self = this;
        let scrollPosition = this.ui.$window.scrollTop();
    	for (var i = 0; i < this.ui.$scrollNavLinkReversed.length; i++) {
    		let $this = $(this.ui.$scrollNavLinkReversed[i]);
            let $currentSection = $($this.attr("href"));
            let sectionTop = $currentSection.offset().top - this.ui.$scrollNav.outerHeight(true);
            if (scrollPosition >= sectionTop) {
                let id = $currentSection.attr('id');
				self.ui.$scrollNavLink.removeClass('active');
                $this.addClass('active');
                return false;
            }
        }
    }
};

module.exports = scrollNav;