const homepageFeed = {
	ui: {
    	homepageFeedBalance_action: '',
        heroOpacity: 1,
        animEnded: false
    },

	init: function() {
		this.bindUI();
        this.bindEvents();
        this.homepageFeedBalance();
	},

	bindUI: function bindUI() {
		this.ui.$feedRightSmall = $(".js-homepageFeed-right-small");
		this.ui.$feedRightLarge = $(".js-homepageFeed-right-large");
	},

	bindEvents: function bindEvents() {
        $(window).on('resize', this.throttle(this.homepageFeedBalance.bind(this), 500));
	},

	homepageFeedBalance: function() {
        let largeHeight = 0
        $('.js-homepageFeed-right-large > a.js-feed-item, .js-homepageFeed-right-large > div.feedPush').not('.is-hidden').each((i, e) => {
            largeHeight += $(e).outerHeight(true)
        });
        largeHeight -= 100

        let hiddenHeight = $('.js-homepageFeed-right-large > a.js-feed-item.is-hidden').first().outerHeight(true)

        if (this.ui.$feedRightSmall.height() < largeHeight) {
			// si on viens de show, et que là on va hide, on est dans une boucle infinis, on coupe le balance du coup
        	if(this.ui.homepageFeedBalance_action == "show"){
				return; 
        	}
        	this.ui.homepageFeedBalance_action = "hide";
            this.ui.$feedRightLarge.find('.js-feed-item').not('.is-hidden').last().addClass('is-hidden')
            if(this.ui.$feedRightLarge.find('.js-feed-item').not('.is-hidden').length>0){
            	this.homepageFeedBalance()
            }
        } else if (this.ui.$feedRightSmall.height() > (largeHeight + hiddenHeight)) {
			// si on viens de hide, et que là on va hide, on est dans une boucle infinis, on coupe le balance du coup
        	if(this.ui.homepageFeedBalance_action == "hide"){
				return; 
        	}
        	this.ui.homepageFeedBalance_action = "show";
            this.ui.$feedRightLarge.find('.js-feed-item.is-hidden').first().removeClass('is-hidden')
            if(this.ui.$feedRightLarge.find('.js-feed-item.is-hidden').first().length>0){
            	this.homepageFeedBalance()
            }
        }
        // sortie "naturelle" sans boucle infinis à cut
        this.ui.homepageFeedBalance_action = "";
    },

    throttle: function(func, limit) {
		let inThrottle
		return function() {
			const args = arguments
			const context = this
			if (!inThrottle) {
				func.apply(context, args)
				inThrottle = true
				setTimeout(() => inThrottle = false, limit)
			}
		}
	}

};

module.exports = homepageFeed;
