// Import Slick
import '../../../gulp/node_modules/slick-carousel'

var thematicHighlightsSlider = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$slider = $('.js-thematic-highlights-slider')
        this.ui.$thematicHighlightsItem = $('.js-thematic-highlights-item')
    },

    bindEvents: function () {
        this.ui.$thematicHighlightsItem.on('click', this.onClickSlide.bind(this))
        this.ui.$thematicHighlightsItem.on('mouseenter', this.itemEnter.bind(this))
        this.ui.$thematicHighlightsItem.on('mousemove', (e) => {
            window.requestAnimationFrame(() => this.itemHover(e))
        })
        this.ui.$thematicHighlightsItem.on('mouseleave', this.itemLeave.bind(this))

        this.ui.$slider.slick({
            centerMode: true,
            centerPadding: '25%',
            slidesToShow: 1,
            arrows: false,
            speed: 800,
            responsive: [
                {
                    breakpoint: 1050,
                    settings: {
                        centerPadding: '10%',
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        centerPadding: 0,
                    },
                },
            ],
        })
    },

    onClickSlide: function (e) {
        let $slide = $(e.target).closest('.slick-slide')
        if ($slide.length <= 0) {return false}

        let currentIndex = $(e.target).closest('.js-thematic-highlights-slider').slick('slickCurrentSlide')
        
        if ($slide.data('slick-index') < currentIndex) {
            $(e.target)
            .closest('.js-thematic-highlights-slider')
            .slick('slickPrev')
        } else if ($slide.data('slick-index') > currentIndex) {
            $(e.target)
            .closest('.js-thematic-highlights-slider')
            .slick('slickNext')
        }
    },

    itemEnter: function (e) {
        e.stopPropagation()
        this.ui.$currentThematicItem = $(e.currentTarget)[0]
    },

    itemHover: function (e) {
        e.stopPropagation()
        if (this.ui.$currentThematicItem === false) {
            return false
        }
        let $this = $(this.ui.$currentThematicItem)
        let $targetTitle = $this.find('.js-thematic-highlights-title')

        let mouseX = e.clientX - $this[0].getBoundingClientRect().left
        let mouseY = e.clientY - $this[0].getBoundingClientRect().top
        let width = $this[0].getBoundingClientRect().width
        let height = $this[0].getBoundingClientRect().height

        let translateX = ((mouseX - width / 2) / (width / 2)) * (width / 50)
        let translateY = ((mouseY - height / 2) / (height / 2)) * (height / 50)

        $targetTitle &&
            $targetTitle.css({
                transform: 'translate3d(' + translateX + 'px, ' + translateY + 'px, 0) scale(1.04)',
            })
    },

    itemLeave: function (e) {
        e.stopPropagation()
        let $this = $(this.ui.$currentThematicItem)
        let $targetTitle = $this.find('.js-thematic-highlights-title')
        $targetTitle &&
            $targetTitle.css({
                transform: 'translate3d(0,0,0) scale(1)',
            })
        this.ui.$currentThematicItem = false
    },
}

module.exports = thematicHighlightsSlider
