// Import Slick
var showMoreAjax = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$list = $('.js-ajax-list')
        this.ui.$listContent = $('.js-ajax-list-content')
        this.ui.$showMore = $('.js-ajax-show-more')
    },

    bindEvents: function () {
        this.ui.$showMore.on('click', (e) => this.showMore(this, e))
    },

    showMore: function (that, e) {
        let $button = $(e.currentTarget)
        let $currentList = $button.closest('.js-ajax-list')
        let $pageInput = $currentList.find('.js-ajax-page-input')
        let currentPage = $pageInput.val()
        let formDatas = $currentList
            .find(':input')
            .filter(function (index, element) {
                return $(element).val() !== ''
            })
            .serialize()

        $button.addClass('is-loading')
        $.ajax({
            url: ajax_object.ajax_url,
            type: 'POST',
            data: formDatas,
            success: (result) => {
                currentPage++
                $pageInput.val(currentPage)
                let $result = $(result)
                this.ui.$listContent.append($result)
                $result.find(".js-study-card").eq(0).focus();
                $button.removeClass('is-loading');
                window.lazyLoadInstance.update();
                var itemPerPage = parseInt($button.attr("data-item-per-page"));
                if(itemPerPage && !isNaN(itemPerPage) && $result.find(">*").length < itemPerPage){
                    $button.hide();
                }
            },
            error: (error) => {
                $button.removeClass('is-loading').addClass('is-error')
            },
        })

        // setTimeout(() => {
        //     this.ui.$listContent
        //         .find('.studyList__cell')
        //         .clone()
        //         .hide()
        //         .appendTo(this.ui.$listContent)
        //         .slideDown(200)
        //     $button.removeClass('is-loading')
        // }, 1000)
    },
}

module.exports = showMoreAjax
