var studyHero = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$studyHero = $('.js-study-hero')
        this.ui.$studyHeroContainer = $('.js-study-hero-container')
        this.ui.$studyHeroItem = $('.js-study-hero-item')
        this.ui.$studyHeroLanguage = $('.js-study-hero-language')


        this.ui.$studyToggleSharer = $('.js-toggle-study-sharer')
        this.ui.$studySharer = $('.js-study-sharer')
    },

    bindEvents: function () {
        $(window).on('scroll resize', this.stickyStudy.bind(this))
        this.ui.$studyHeroLanguage.on('change', (e) => this.changeLanguage(e))

        this.ui.$studyToggleSharer.on('click', (e) => this.toggleSharer(e))
    },

    toggleSharer: function () {
        if(this.ui.$studySharer.hasClass('is-active')) {
            this.ui.$studySharer.removeClass('is-active')
            this.ui.$studySharer.slideUp(200)
        } else {
            this.ui.$studySharer.addClass('is-active')
            this.ui.$studySharer.slideDown(200)
        }
    },

    stickyStudy: function () {
        /* Controling the summary position and overlap */
        if (window.pageYOffset > this.ui.$studyHero.offset().top) {
            this.ui.$studyHeroContainer.hasClass('is-fixed') === false &&
                this.ui.$studyHeroContainer.addClass('is-fixed')
        } else if (this.ui.$studyHeroContainer.hasClass('is-fixed')) {
            this.ui.$studyHeroContainer.removeClass('is-fixed')
        }
    },

    changeLanguage: function (e) {
        /* Controling the hero study document language */
        let langLink = $(e.currentTarget.selectedOptions).data('link')
        if (langLink) {
            window.location.href = langLink
        } else {
            let selectedLanguage = $(e.currentTarget).val()
            this.ui.$studyHeroLanguage.val(selectedLanguage)

            let $currentItem = this.ui.$studyHeroItem.filter((i, e) => {
                return $(e).data('language') === selectedLanguage
            })
            this.ui.$studyHeroItem.removeClass('is-active')
            $currentItem.addClass('is-active')
        }
    },
}

module.exports = studyHero
