var textCloud = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
        this.showReadMore()
    },

    bindUI: function () {
        this.ui.$readMore = $('.js-textCloud-readmore')
        this.ui.$block = $('.js-textCloud-block')
        this.ui.$text = $('.js-textCloud-text')
    },

    bindEvents: function () {
        $(window).on('resize', this.showReadMore.bind(this))
        this.ui.$readMore.on('click', this.toggleBlock.bind(this))
    },

    toggleBlock: function (b) {
        let $this = $(b.currentTarget)
        let blockData = $this.data('block')
        if (blockData) {
            $('.js-textCloud-block[data-block="'+ blockData +'"]').toggleClass('is-active')
        } else {
            $this.closest('.js-textCloud-block').toggleClass('is-active')
        }
    },

    showReadMore: function (b) {
        this.ui.$block.each((i, b) => {
            let $text = $(b).find('.js-textCloud-text')[0]
            let $readMore = $(b).find('.js-textCloud-readmore')
            if (($text.scrollHeight > ($text.clientHeight + 10)) || ($text.scrollHeight > 128)) {
                $readMore.removeClass('is-hidden')
            } else {
                $readMore.addClass('is-hidden')
            }
        })
    },



}

module.exports = textCloud
