var feedItemHover = {
    ui: {},

    init: function () {
        this.bindUI()
        this.cardResize()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$feedItemCover = $('.js-feed-item-cover')
        this.ui.$currentFeedItem = false

        // let $targetImage = this.ui.$feedItemCover.find('.js-feed-item-image')
        // $targetImage.css({
        //     width: $targetImage.find("img").css('width')
        // });
    },

    bindEvents: function () {
        this.ui.$feedItemCover.on('mouseenter', this.cardEnter.bind(this))
        this.ui.$feedItemCover.on('mousemove', (e) => {
            window.requestAnimationFrame(() => this.cardHover(e))
        })
        this.ui.$feedItemCover.on('mouseleave', this.cardLeave.bind(this))
        $(window).on('resize', this.cardResize.bind(this))
    },

    cardResize: function () {
        // this.ui.$feedItemCover.each(function(){
        //     let $this = $(this)
        //     ,   $feedImage = $this.find(".js-feed-item-image")
        //     ,   h = $this.outerHeight(true)
        //     ,   new_h = h*.8;
        //     $feedImage.css({"height":new_h});
        // });
    },

    cardEnter: function (e) {
        e.stopPropagation()
        this.ui.$currentFeedItem = $(e.currentTarget)[0]
    },

    cardHover: function (e) {
        e.stopPropagation()

        if (this.ui.$currentFeedItem === false) {
            return false
        }
        let $this = $(this.ui.$currentFeedItem)
        let $targetImage = $this.find('.js-feed-item-image')
        if ($this.find('.js-feed-item-image').length <= 0) {
            return false
        }

        let mouseX = e.clientX - $this[0].getBoundingClientRect().left
        let mouseY = e.clientY - $this[0].getBoundingClientRect().top
        let width = $this[0].getBoundingClientRect().width
        let height = $this[0].getBoundingClientRect().height

        let translateX = ((mouseX - width / 2) / (width / 2)) * (width / 100)
        let translateY = ((mouseY - height / 2) / (height / 2)) * (height / 100)

        $targetImage &&
            $targetImage.css({
                transform:
                    'translate3d(' +
                    (translateX - 50) +
                    '%, ' +
                    (translateY - 50) +
                    '%, 0) scale(1.04)',
            })
    },

    cardLeave: function (e) {
        e.stopPropagation()
        let $this = $(this.ui.$currentFeedItem)
        let $targetImage = $this.find('.js-feed-item-image')
        $targetImage &&
            $targetImage.css({
                transform: 'translate3d(-50%, -50%, 0) scale(1)',
            })
        this.ui.$currentFeedItem = false
    },
}

module.exports = feedItemHover
