const materialForm = {

	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.ui.$inputs.each(function(){
			materialForm.toggleHasValueClass($(this));
		});
	},
	
	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$inputs = $("input, textarea");
	},

	bindEvents: function() {
		this.ui.$inputs.on('focusout', this.toggleHasValueClass.bind(this));
	},

    toggleHasValueClass: function(e) {
		let self = this;
		let $this = $(e.target);
		
        if($this.val().length > 0){
            $this.addClass("has-value");
        } else {
            $this.removeClass("has-value");
        }
    }

};

module.exports = materialForm;
