var studyHero = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$input = $('.js-don__input')
        this.ui.$form = $('.js-don__form')
        this.ui.$don__montantreduc = $('.js-don__montantreduc')
    },

    bindEvents: function () {
        this.ui.$input.on('keyup', (e) => this.changeDon(e))
    },

    changeDon: function (e) {
        let val = this.ui.$input.val();
        this.ui.$input.attr("size", val.length);
        
        val = val.replace(",", "."); 
        var reduc= val*66/100;
        reduc = Math.round(reduc*100)/100;  

        this.ui.$don__montantreduc.text(reduc);
    },
}

module.exports = studyHero
