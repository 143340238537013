var team = {
    ui: {},

    init: function () {
        this.bindUI()
        this.bindEvents()
    },

    bindUI: function () {
        this.ui.$teamMember = $('.js-team-member')
        this.ui.$teamSheet = $('.js-team-sheet')
    },

    bindEvents: function () {
        this.ui.$teamMember.on('click', this.memberSelect.bind(this))
    },

    memberSelect: function (m) {
        let $this = $(m.currentTarget)

        this.ui.$teamMember.removeClass('is-active')
        $this.addClass('is-active')

        this.ui.$teamSheet.removeClass('is-active')
        let memberIndex = $this.data('member')
        $('.js-team-sheet[data-member="'+ memberIndex +'"]').addClass('is-active')
    },

}

module.exports = team
