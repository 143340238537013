var navScrollHighlight = {

	ui: {},

	sectionIdToNavigationLink: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

        this.ui.$navSections.each(function() {
			navScrollHighlight.sectionIdToNavigationLink[$(this).attr('id')] = $(".js-nav-link[href='#" + $(this).attr('id') + "']");
        });

		this.navOnScroll();
	},
	
	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);

		this.ui.$anchorMenuFixed = $(".js-anchor-menu-fixed");
		
		this.ui.$navigationLinks = $(".js-nav-link");
		this.ui.$navSections = $(".js-nav-section");
		this.ui.$navSectionsReversed = this.ui.$navSections.get().reverse();
	},

	bindEvents: function() {
		this.ui.$window.on('scroll', this.navOnScroll.bind(this));
	},

    navOnScroll: function(e) {
		let self = this;
		let $this = $(e.target);
		
		this.updateNavHighlight();

		if ($this.scrollTop() >= $(".js-target-to-display-fixed-menu").offset().top) {
			this.ui.$anchorMenuFixed.slideDown(200);
		} else {
			this.ui.$anchorMenuFixed.slideUp(200);
		}
	},

    updateNavHighlight: function() {
		let self = this;
		
        let scrollPosition = this.ui.$window.scrollTop();

        let addToInitialOffset = 110;
        if (this.ui.$window.width() < 1080) {
            addToInitialOffset = 76;
        }
        addToInitialOffset += 115;

        this.ui.$navSectionsReversed.each(function() {
            let $currentSection = $(this);
            let sectionTop = $currentSection.offset().top - addToInitialOffset;

            if (scrollPosition >= sectionTop) {
                let id = $currentSection.attr('id');
                let $navigationLink = self.sectionIdToNavigationLink[id];
                if (!$navigationLink.hasClass('active')) {
					self.ui.$navigationLinks.removeClass('active');
                    $navigationLink.addClass('active');
                }
                return false;
            }
        });
    }

};

module.exports = navScrollHighlight;
